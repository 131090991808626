.results {
  padding: 2rem;
  &__summary {
    font-size: 1.2rem;
  }
  &__header {
    margin-top: 1rem;
    .column:last-child {
      display: flex;
      justify-content: flex-end;
    }
  }
}
