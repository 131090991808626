.modal-card-head {
  margin-bottom: 0;
}
.modal-card-body {
  .content p {
    margin-bottom: 1rem;
  }
}

.modal-card-foot {
  margin-top: 0;
}
