.intro {
  h1 {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.25;
  }
  p {
    & + p {
      margin-top: 0.25rem;
    }
  }
}
