@import '../_variables.sass';
@import '~react-bulma-components/src/index.sass';

.select {
  width: 100%;
  select {
    width: 100%;
  }
}

.has-heading {
  padding-bottom: 0;
}

.is-right {
  margin-left: auto;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.Toastify__toast--info {
  background: #3273dc;
}

.logo-wrap {
  display: inline-block;
}
