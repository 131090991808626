.feedback {
  display: inline-flex;
  button {
    display: flex;
    padding: 0;
    font-size: 1.5rem;
    background: transparent;
    border: none;
    cursor: pointer;
    &[disabled] {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}
