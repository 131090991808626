.keywords {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & > span:first-child {
    margin-right: 0.25rem;
  }
}

.keyword {
  display: inline-flex;
  align-items: center;
}
