.control {
  label {
    white-space: normal;
  }
  input[type='checkbox'] + label {
    margin-left: 0.5rem;
  }
  input[type='checkbox'] {
    margin-right: 0.5rem;
  }
}
