.loading {
  display: flex;
  justify-content: center;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  width: 100%;
  height: 3rem;
  // transform: translate(-50%, -50%);
  &__indicator {
    // position: absolute;
    width: 3rem;
    height: 3rem;
    border-top: 0.5rem solid gray;
    border-bottom: 0.5rem solid gray;
    border-right: 0.5rem solid gray;
    border-left: 0.5rem solid transparent;
    border-radius: 50%;
    // animation: loading 300ms linear repeat;
    animation: loading 0.5s infinite linear;
  }
}

@keyframes loading {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
