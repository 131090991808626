.info {
  text-align: left;
  // position: absolute;
  // bottom: calc(100% + 20px);
  // left: 50%;
  // padding: 1em;
  // width: 100%;
  // background: #fff;
  font-size: 0.9rem;
  // box-shadow: 0px 0px 4px 0px rgba(black, 0.5);
  // transform: translateX(-50%);
  // @media screen and (min-width: 769px) {
  //   width: 50%;
  // }
  // &__arrow {
  //   position: absolute;
  //   top: calc(100% - 13px);
  //   left: 50%;
  //   width: 20px;
  //   height: 20px;
  //   background: #fff;
  //   box-shadow: 4px 4px 6px -3px rgba(0, 0, 0, 0.3);
  //   transform: translateX(-50%) rotate(45deg);
  // }
}
.flex {
  display: flex;
}
.extraflextra {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
}

.logo {
  img {
    width: 256px;
  }
}
