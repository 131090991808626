.resultslist {
  width: 100%;
  &.is-loading {
    position: relative;
    padding: 4rem;
  }
  &__item {
    margin-top: 2rem;
  }
}

.resitm {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &__content {
    flex: 1 1 100%;
  }
  &__title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  &__text {
    margin-top: 0.5rem;
    font-size: 0.85rem;
  }
  &__link {
    display: inline-block;
    word-break: break-all;
  }
  // &__select {
  // }
  &__section {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid gray;
  }
  &__info {
    margin-top: 0.5rem;
    text-align: center;
  }
  &__info-action {
    padding: 0;
    font-size: 1rem;
    background: transparent;
    border: 0;
    cursor: pointer;
    i {
      transition: transform 300ms ease;
    }
    &[aria-expanded='true'] {
      i {
        transform: rotate(-180deg);
      }
    }
  }
  &__actions {
    display: flex;
    align-items: center;
  }
  &__report {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
  }
}
.control {
  white-space: nowrap;
  label {
    display: inline-block;
    padding-right: 0.5rem;
  }
}
